import {format} from "date-fns";
import {findTimeZone, getUTCOffset} from "timezone-support";
//
export const getOffset = (tz) => {
    // const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const tzInfo = findTimeZone(tz);
    return -getUTCOffset(new Date(), tzInfo).offset
}

// export const formatDateTimeInTz = (date, tz) => {
//     const is24h = isBrowserLocale24h();
//     const offset = getOffset(tz);
//     const timeInTz = addHours(date, offset)
//     if(is24h){
//         return format(timeInTz, 'H:mm')
//         // return dayjs.utc(date).tz(tz, true).format('HH:mm')
//         // return formatInTimeZone(date, tz, 'H:mm')
//         // return format(utcToZonedTime(date, tz), 'H:mm', { timeZone: tz });
//     } else {
//         return format(timeInTz, 'p')
//         // return dayjs.utc(date).tz(tz, true).format('p')
//         // return formatInTimeZone(date, tz, 'p')
//         // return format(utcToZonedTime(date, tz), 'p', { timeZone: tz });
//     }
// }
//
//
export const isBrowserLocale24h = () =>
    !new Intl.DateTimeFormat(undefined, { hour: 'numeric' }).format(0).match(/\s/);


function calculateDateTimeValueForTimezone(date, tz) {
    const origDate = new Date(date);
    const targetTzOffset = getOffset(tz);
    const baseTzOffset = origDate.getTimezoneOffset();

    const dt = new Date(origDate.valueOf() + (baseTzOffset + targetTzOffset) * 60 * 1000);
    return dt;
}

export const formatDayOfWeekInGivenTz = (date, tz) => {
    const dt = calculateDateTimeValueForTimezone(date, tz);
    return format(dt, 'EEEE');
}

export const formatDateTimeInGivenTz = (date, tz, withDate = false) => {
    const dt = calculateDateTimeValueForTimezone(date, tz);
    if(isBrowserLocale24h()) {
        if(withDate){
            return format(dt, 'd MMMM yyyy, H:mm');
        }
        return format(dt, 'H:mm');
    } else {
        if (withDate) {
            return format(dt, 'd MMMM yyyy, p');
        }
        return format(dt, 'p');
    }
}
