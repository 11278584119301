import React from 'react'
import SessionRow from "./SessionRow";
import sha1 from "crypto-js/sha1";
import styled from 'styled-components';


const StyledHeaderRow = styled.tr`
    min-height: 65px;
    height: 65px;
    font-variant: small-caps;
    font-size: 1.1em;
`

const StyledHeader = styled.th`
  @media screen and (min-width:641px) {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }`

const ChairmanSpan = styled.span`
    font-weight: normal;
    font-size: 0.9em;
    font-variant: none;
`

function Session({name, session, loc, locUrl, chairman}){
    if(!session) return null

    session.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    })

    const rows = session.map(presentation => (
        <SessionRow
            key={sha1(presentation.title)}
            presentation={presentation}
        />)
    )

    return (

        <table className="table is-hoverable is-fullwidth">
            <thead>
            <StyledHeaderRow >
                <StyledHeader colSpan="2">
                    {name}<br/>

                    <a href={locUrl} target="_blank" rel="noopener noreferrer">{loc}</a><br/>
                    {chairman &&
                    <ChairmanSpan>Chairman: {chairman}</ChairmanSpan>
                    }
                </StyledHeader>
                <th></th>
            </StyledHeaderRow>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
   )
}

export default Session
