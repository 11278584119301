import React from 'react'
import styled from "styled-components";
import logo from '../Assets/header_logo.png'
const ConfTitle = styled.h1`
  font-size: 1.5em;
  font-weight: bold;
`

function Header(props){
    return (
        <div className="panel-heading">
            <div className="columns">
                <div className="column" style={{display: "flex", alignItems: "center" }}>
                    <img src={logo} alt="NUMIFORM 2023 Logo" style={{width: 80, marginRight: 20}}/>
                    <ConfTitle >NUMIFORM 2023 Programme</ConfTitle>
                </div>
            </div>
        </div>
    )
}

export default Header
