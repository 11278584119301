import {useAtom} from "jotai";
import {timezoneAtom} from "../Atoms/timezone";
import {useState} from "react";
import {useResetAtom} from "jotai/utils";

export const TimezoneSettings = () => {
    const [localTz, setLocalTz] = useState(false);
    const [, setTimezone] = useAtom(timezoneAtom);
    const resetTimezone = useResetAtom(timezoneAtom)

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handleLocalTzClick = () => {
        if(localTz){
            resetTimezone();
            setLocalTz(false);
        } else {
            setLocalTz(true);
            setTimezone(tz);
        }
    }

    if(tz === 'Europe/Warsaw') return null;

    return (
        <div style={{display: "flex", justifyContent: 'flex-end', padding: "10px 10px 10px 10px"}}>
            <label className="switch is-rounded">
                <input type="checkbox" value="false" onChange={handleLocalTzClick} checked={localTz}/>
                <span className="check is-info"></span>
                <span className="control-label">Show presentation times according to my timezone</span>
            </label>
        </div>
    )
}
