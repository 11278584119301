import React from 'react'
import {Link} from "react-router-dom";
import styled from 'styled-components'
import {timezoneAtom} from "../Atoms/timezone";
import {useAtom} from "jotai";
import {formatDayOfWeekInGivenTz} from "../Utils/datetime";

const StyledLink = styled.span`
  @media (max-width: 330px) {
    font-size: 0.795em;
  }

  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`


function ScheduleTabHeader({confDay, day, activeDay, onClick, bold}) {
    const [tz] = useAtom(timezoneAtom);
    const title = formatDayOfWeekInGivenTz(confDay, tz);
    return (
        <Link
            to={`?day=${day}`}
            onClick={() => onClick(day)}
            className={day === activeDay ? "is-active" : ""}
        >
            <StyledLink bold={+bold}>{title}</StyledLink>
        </Link>
    )
}

export default ScheduleTabHeader
