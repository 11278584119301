import React, {useState} from 'react';
import ScheduleTabsContent from "./ScheduleTabsContent";
import {useSearchParams} from "react-router-dom";
import ScheduleTabHeader from "./ScheduleTabHeader";
import styled from "styled-components";

const confDays = [
    new Date(Date.UTC(2023, 5, 26, 6, 45, 0)),
    new Date(Date.UTC(2023, 5, 27, 7, 0, 0)),
    new Date(Date.UTC(2023, 5, 28, 7, 0, 0)),
    new Date(Date.UTC(2023, 5, 29, 7, 0, 0))]

const days = ['Monday', 'Tuesday', 'Wednesday'];

const PanelTabs = styled.p`
  font-size: 1.1em;

  span {
    font-weight: 600;
  }
`

function ScheduleTabs({data, highlightedDays}) {

    const [searchParams] = useSearchParams();
    let selectedTab = "Monday"

    const qDay = searchParams.get('day');

    if (qDay) {
        if (days.includes(qDay)) {
            selectedTab = qDay;
        }
    } else {
        const now = new Date()
        const confStart = new Date(2023, 6, 26);
        const confFinish = new Date(2023, 6, 29);
        if (now >= confStart && now <= confFinish) {
            const d = now.getDay()
            selectedTab = days[d];
        }

    }


    const [day, setDay] = useState(selectedTab);


    return (
        <>
            <PanelTabs className="panel-tabs is-centered">
                <ScheduleTabHeader confDay={confDays[0]} day="Monday" activeDay={day} onClick={setDay} bold={highlightedDays[1]}/>
                <ScheduleTabHeader confDay={confDays[1]} day="Tuesday" activeDay={day} onClick={setDay} bold={highlightedDays[2]}/>
                <ScheduleTabHeader confDay={confDays[2]} day="Wednesday" activeDay={day} onClick={setDay} bold={highlightedDays[3]}/>
                <ScheduleTabHeader confDay={confDays[3]} day="Thursday" activeDay={day} onClick={setDay} bold={highlightedDays[3]}/>
            </PanelTabs>

            <ScheduleTabsContent data={data} activeDay={day}/>
        </>
    );
}

export default ScheduleTabs
