import EuLogo from "../Assets/logo_eu.png";
import EuFunds from "../Assets/logo_ef.png";
import Doskonala from "../Assets/logo_doskonala.png";
import NcbrLogo from "../Assets/logo_ncbr.png";
import RpLogo from "../Assets/logo_rp.png";
import MenLogo from "../Assets/logo_men.png";
import KrakowLogo from "../Assets/logo_krakow.png";

import styled from 'styled-components'

const FooterContainer = styled.footer`
  padding: 2rem 1.5rem 2rem;
`

const Footer = () => {
    return (
        <FooterContainer className="footer">

            <div className="container">
                <div className="has-text-centered p-4">
                    <p>
                        The activity is co-financed from the state budget under the programme of the Minister of Education and Science called 'Excellent Science' project no. DNK/SP/514574/2021
                    </p>
                </div>

                <div className="columns is-align-items-baseline p-4">

                    <div className="column has-text-centered">
                        <img src={MenLogo} alt="Ministry of education and science"/>
                    </div>

                    <div className="column has-text-centered">
                        <img src={RpLogo} alt="Republic of Poland"/>
                    </div>

                    <div className="column has-text-centered">
                        <img src={Doskonala} alt="Doskonała nauka"/>
                    </div>

                </div>

                <div className="columns is-align-items-baseline p-4">

                    <div className="column has-text-centered">
                        <a href="https://www.dlabiznesu.krakow.pl/nauka" target="_blank" rel="noreferrer">
                        <img src={KrakowLogo} alt="krakow"/>
                        </a>
                    </div>

                </div>

                {/*<div className="has-text-centered p-4">*/}
                {/*    <p>*/}
                {/*This activity has received funding from the European Union from the European Regional Development Fund under the Intelligent Development Operational Program*/}
                {/*    </p>*/}
                {/*</div>*/}
                {/*<div className="columns is-align-items-baseline p-4">*/}
                {/*    <div className="column has-text-centered">*/}
                {/*        <img src={EuFunds} alt="European Funds"/>*/}
                {/*    </div>*/}

                {/*    <div className="column has-text-centered">*/}
                {/*        <img src={NcbrLogo} alt="National Centre for Research and Development"/>*/}
                {/*    </div>*/}

                {/*    <div className="column has-text-centered">*/}
                {/*        <img src={EuLogo} alt="European Union"/>*/}
                {/*    </div>*/}

                {/*</div>*/}

                <div className="has-text-centered p-4">
                    <p>
                        <a href="http://www.numiform2023.agh.edu.pl/"> <strong>NUMIFORM 2023</strong></a> - The 14-th
                        International Conference
                        on Numerical Methods in Industrial Forming Processes
                    </p>
                </div>
            </div>
        </FooterContainer>
    )
}

export default Footer
