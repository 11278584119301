import React from 'react'

import styled from 'styled-components';
import {abstractAtom} from "../Atoms/abstract";
import {useAtom} from "jotai";
import {timezoneAtom} from "../Atoms/timezone";
import {formatDateTimeInGivenTz} from "../Utils/datetime";
import {Tooltip} from "react-tooltip";

const Title = styled.p`
  text-decoration: ${props => props.canceled ? 'line-through' : 'none'};
  font-weight: 600;
`

const Authors = styled.p`
  font-size: 0.8em;
  
`

const TimeHeader = styled.td`
  width: 70px;
`

const Td = styled.td`
    padding: 0.5em 0em !important;
`

const FormatedLink = styled.a`
    color: #3273dc;
    &:visited, &:hover, &:active {
      color: #3273dc;
    }
`

const Tr = styled.tr`
  &:last-child td {
    border: none;
  }
`


function SessionRow({presentation}){
    const [tz] = useAtom(timezoneAtom);
    const [, setAbstract] = useAtom(abstractAtom)

    const {authors, date, title, canceled, content} = presentation

    const formattedDate = formatDateTimeInGivenTz(date, tz);
    const formattedAuthors = authors.join(", ")

    const showAbstract = (presentation) => {
        setAbstract(presentation);
    }

    const TextOrLink = ({title, content}) => {
        if(content)
            return <FormatedLink onClick={() => showAbstract(presentation)}>{title}</FormatedLink>
        else
            return <span>{title}</span>
    }

    const tooltipContent = formatDateTimeInGivenTz(date, tz, true);

    return (
        <>
        <Tr>
            <TimeHeader data-tooltip-id="dt-tooltip" data-tooltip-content={tooltipContent} data-tooltip-variant="info">{formattedDate}</TimeHeader>
            <Td>
                <Title canceled={canceled} >
                    <TextOrLink title={title} content={content}/>
                </Title>
                <Authors>
                    {formattedAuthors}
                </Authors>
            </Td>
        </Tr>
            <Tooltip id="dt-tooltip" />
        </>
    )
}

export default SessionRow
